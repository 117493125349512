@import '../../styles/customMediaQueries.css';
.box {
  height: auto;
  float: left;
  transition: 0.5s linear;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 15px;
  text-align: center;

  background: transparent;
  font-weight: 900;
  margin-bottom: 20px;

  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  line-height: 20px;
  border: 1px solid var(--colorGrey100);
  background-color: white;
  border-radius: var(--borderRadiusMedium);
  padding: var(--marketplaceButtonSmallDesktopPadding);

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.box:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 4px;
  width: 100%;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  box-sizing: border-box;
  transform: translateX(100%);
}

.box:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  transform: translateX(-100%);
}

.box:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.box:hover:before {
  border-color: #262626;
  height: 100%;
  transform: translateX(0);
  transition: 0.3s transform linear, 0.3s height linear 0.3s;
}

.box:hover:after {
  border-color: #262626;
  height: 100%;
  transform: translateX(0);
  transition: 0.3s transform linear, 0.3s height linear 0.5s;
}

button {
  color: black;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  max-width: 180px;
}

.map-icon {
  margin-left: 10px;
}
